import CartItem = Definitions.CartItem;
import CartItemResponse = Definitions.CartItemResponse;
import HydratedProduct = Definitions.HydratedProduct;
import CartItemCustomerDeliveryDetailInfo = Definitions.CartItemCustomerDeliveryDetailInfo;
import Price = Definitions.Price;
import { GetProductByIdFunc } from "../hooks/useProducts/useProducts";

export const decryptOrderHash = (orderHash = "") => {
  try {
    const [
      _orderId,
      _isAsap,
      deliveryTime,
      _netPriceWithVat,
      orderCount,
      customerToken,
    ] = atob(orderHash).split("_");
    return {
      deliveryTime,
      customerToken,
      orderCount,
    };
  } catch (err) {
    console.log("Error while decrypting hash", err);
    return {};
  }
};

export const mapInternalCartItemsToOrderItems = (
  cartItems: CartItemResponse[] = []
): CartItem[] => {
  return cartItems.map(
    (item) =>
      ({
        count: item.count,
        id: item.id,
        product: item.id,
        productId: item.id,
        sequenceId: item.sequenceId,
        addons: item.addons.map((addon) => ({
          ...addon,
          available: addon.available,
        })),
        deliveryDetailInfo: item.deliveryDetailInfo,
      } as CartItem)
  ) as CartItem[];
};

const localStorageStatus = () => {
  if (typeof localStorage !== "undefined") {
    try {
      localStorage.setItem("___feature_test", "yes");
      if (localStorage.getItem("___feature_test") === "yes") {
        localStorage.removeItem("___feature_test");
        return "enabled";
      } else {
        return "disabled";
      }
    } catch (e) {
      return "disabled";
    }
  } else {
    return "unavailable";
  }
};
const timeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    return null;
  }
};
export const getBrowserInfo = () => ({
  platform: navigator.platform,
  userAgent: navigator.userAgent,
  language: navigator.language,
  storageEnabled: localStorageStatus(),
  cookiesEnabled: navigator.cookieEnabled ? "enabled" : "disabled",
  screen: window.screen
    ? {
        width: window.screen.width,
        height: window.screen.height,
      }
    : null,
  window: { width: window.innerWidth, height: window.innerHeight },
  document: document.documentElement
    ? {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      }
    : null,
  timezone: timeZone(),
  timezoneOffset: new Date().getTimezoneOffset(),
});

interface BaseCartItemProps {
  count: number;
  deliveryDetailInfo?: CartItemCustomerDeliveryDetailInfo;
  productId: string;
  product: string;
  id: string;
}

type InternalCartItemProductProps = Partial<
  Omit<HydratedProduct, "id" | "addons">
>;

export interface RequestCartItem extends BaseCartItemProps {
  addons: string[];
  price: Price;
  singlePrice: Price;
  deliveryDetailInfo?: CartItemCustomerDeliveryDetailInfo;
  sequenceId: number;
}

export type PersistedCartItem = RequestCartItem;

export interface InternalCartItemAddon
  extends BaseCartItemProps,
    InternalCartItemProductProps {
  includedAddon?: boolean;
}

export interface InternalCartItem
  extends Omit<RequestCartItem, "addons" | "price" | "singlePrice">,
    InternalCartItemProductProps {
  basePrice: string;
  singlePrice: Price;
  addons: InternalCartItemAddon[];
}

export const mapOrderResponseCartItemToStoredCartItem = (
  responseCartItem: CartItemResponse
): RequestCartItem => ({
  count: responseCartItem.count,
  addons: responseCartItem.addons.map((addon) => addon.id),
  deliveryDetailInfo: responseCartItem.deliveryDetailInfo,
  sequenceId: responseCartItem.sequenceId,
  productId: responseCartItem.id,
  product: responseCartItem.id,
  id: responseCartItem.id,
  price: responseCartItem.price,
  singlePrice: responseCartItem.singlePrice,
});
export const mapStoredCartItemToInternalCartItem =
  (getProductById: GetProductByIdFunc) =>
  (requestCartItem: RequestCartItem): InternalCartItem => {
    const product = getProductById(requestCartItem.id);
    const addonsCountMap: { [id: string]: number } = requestCartItem.addons
      .map((addon) => {
        return {
          id: addon,
          count: 1,
        } as InternalCartItemAddon;
      })
      .reduce((acc, cur) => {
        if (!acc[cur.id]) {
          acc[cur.id] = cur.count;
        } else {
          acc[cur.id] += cur.count;
        }
        return acc;
      }, {});
    const addons: InternalCartItemAddon[] = Object.keys(addonsCountMap).map(
      (addonId) => {
        const addonProduct = getProductById(addonId);
        return {
          id: addonProduct.id,
          basePrice: addonProduct.price.withVat,
          image: addonProduct.image,
          productId: addonProduct.id,
          count: addonsCountMap[addonId],
          product: addonProduct.id,
          ...addonProduct,
        } as InternalCartItemAddon;
      }
    );
    const newCartItem: InternalCartItem = {
      ...product,
      id: product.id,
      image: product.image,
      productId: product.id,
      tags: product.tags,
      price: requestCartItem?.price ? requestCartItem.price : product.price,
      singlePrice: requestCartItem?.singlePrice,
      deliveryDetailInfo: requestCartItem.deliveryDetailInfo,
      basePrice: product.price.withVat,
      count: requestCartItem.count,
      name: product.name,
      sequenceId: requestCartItem.sequenceId,
      available: product.available,
      product: product.id,
      description: product.description,
      addons,
    };
    return newCartItem;
  };

export const mapInternalAddonsToRequestAddons = (
  internalCartItemAddons: InternalCartItemAddon[]
) =>
  internalCartItemAddons.flatMap((addon) => Array(addon.count).fill(addon.id));

export const mapInternalCartItemToRequestCartItem = (
  cartItem: InternalCartItem
): RequestCartItem => {
  return {
    count: cartItem.count,
    addons: mapInternalAddonsToRequestAddons(cartItem.addons),
    deliveryDetailInfo: cartItem.deliveryDetailInfo,
    sequenceId: cartItem.sequenceId,
    productId: cartItem.id,
    product: cartItem.id,
    id: cartItem.id,
    price: cartItem.price,
    singlePrice: cartItem.price,
  };
};
