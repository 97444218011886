import useSWR, { SWRConfiguration } from "swr";

import { FetcherFunctionResponse, PublicAPIFetcher } from "../fetcher";
import { AccountAPIFetcher } from "../fetcher/accountAPI";
import B2CCreateAccountRequest = Definitions.B2CCreateAccountRequest;
import BasicAccountInfo = Definitions.BasicAccountInfo;
import AddressBookInfo = Definitions.AddressBookInfo;
import AddressBookEntryInfo = Definitions.AddressBookEntryInfo;

export const register = (
  newAccountRequest: B2CCreateAccountRequest
): Promise<any> => {
  return PublicAPIFetcher<BasicAccountInfo>("/b2c/account/register", {
    method: "POST",
    data: newAccountRequest,
  });
};

export const getAddressBook = async () => {
  return AccountAPIFetcher<AddressBookInfo>("/b2c/account/addressbook");
};

export const saveAddress = async (
  address: Omit<AddressBookEntryInfo, "id">
) => {
  return AccountAPIFetcher<AddressBookEntryInfo>("/b2c/account/addressbook", {
    method: "POST",
    data: address,
  });
};

export const updateAddress = async (address: AddressBookEntryInfo) => {
  return AccountAPIFetcher<AddressBookInfo>(
    `/b2c/account/addressbook/${address.id}`,
    {
      method: "PUT",
      data: address,
    }
  );
};

export const setStandardDeliveryAddress = async (id: string) => {
  return AccountAPIFetcher<any>(
    `/b2c/account/addressbook/standarddeliveryaddress/${id}`,
    {
      method: "PUT",
    }
  );
};

export const setStandardInvoiceAddress = async (id: string) => {
  return AccountAPIFetcher<any>(
    `/b2c/account/addressbook/standardinvoiceaddress/${id}`,
    {
      method: "PUT",
    }
  );
};

export const deleteAddress = async (id: string) => {
  return AccountAPIFetcher<any>(`/b2c/account/addressbook/${id}`, {
    method: "DELETE",
  });
};

export const whoAmI = (
  _userToken: string,
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<BasicAccountInfo | undefined> => {
  const { error, data, mutate } = useSWR<BasicAccountInfo | undefined, string>(
    () => (!!_userToken ? `whoAmI_${_userToken}` : null),
    () =>
      AccountAPIFetcher<BasicAccountInfo>("/b2c/account/whoami", {
        method: "GET",
        token: _userToken,
      }),
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};
