import StorageAdapterGenerator from "./StorageAdapterGenerator";

const FIRST_TIME_USER_SCREEN_VISITED_KEY = "ss.newUserScreenVisited";
export const FirstTimeUserScreenVisitedStorageAdapter =
  StorageAdapterGenerator<boolean>(
    FIRST_TIME_USER_SCREEN_VISITED_KEY,
    false,
    true
  );

const USER_LOCATION_MATCHED_KEY = "ss.userLocationMatched";
export const UserLocationMatchedStorageAdapter = StorageAdapterGenerator<
  string | null
>(USER_LOCATION_MATCHED_KEY, null, false);
