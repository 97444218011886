import HydratedProduct = Definitions.HydratedProduct;
import { CategoryMap, ProductCategories } from "./Products.interfaces";

export const mapProductsToCategories: (
  products: HydratedProduct[]
) => CategoryMap = (products) => {
  try {
    // Replace mapping from raw products to already cleaned totalCatalog and match the type
    const sortedProducts = [...products].sort((a, b) => {
      return a?.sorting - b?.sorting;
    });
    const categoryMap: CategoryMap = sortedProducts.reduce(
      (acc, cur, i) => {
        const { tags, id } = cur;

        if (tags.includes("product.salad")) {
          acc[ProductCategories.SALADS] = [
            ...acc[ProductCategories.SALADS],
            id,
          ];
        }
        if (tags.includes("product.bowl")) {
          acc[ProductCategories.BOWLS] = [...acc[ProductCategories.BOWLS], id];
        }
        if (tags.includes("ingredient.base")) {
          acc[ProductCategories.BASES] = [...acc[ProductCategories.BASES], id];
        }
        if (tags.includes("ingredient.premium")) {
          acc[ProductCategories.PREMIUMS] = [
            ...acc[ProductCategories.PREMIUMS],
            id,
          ];
        }
        if (tags.includes("ingredient.standard")) {
          acc[ProductCategories.STANDARDS] = [
            ...acc[ProductCategories.STANDARDS],
            id,
          ];
        }
        if (tags.includes("ingredient.topping")) {
          acc[ProductCategories.TOPPINGS] = [
            ...acc[ProductCategories.TOPPINGS],
            id,
          ];
        }
        if (tags.includes("ingredient.dressing")) {
          acc[ProductCategories.DRESSINGS] = [
            ...acc[ProductCategories.DRESSINGS],
            id,
          ];
        }
        if (tags.includes("product.drink")) {
          acc[ProductCategories.DRINKS] = [
            ...acc[ProductCategories.DRINKS],
            id,
          ];
        }
        if (tags.includes("product.dessert")) {
          acc[ProductCategories.DESSERTS] = [
            ...acc[ProductCategories.DESSERTS],
            id,
          ];
        }
        if (tags.includes("addon.bread")) {
          acc[ProductCategories.EXTRAS] = [
            ...acc[ProductCategories.EXTRAS],
            id,
          ];
        }
        if (tags.includes("product.side") || tags.includes("addon.bread")) {
          acc[ProductCategories.SIDES] = [...acc[ProductCategories.SIDES], id];
        }
        return acc;
      },
      {
        [ProductCategories.SALADS]: [],
        [ProductCategories.BOWLS]: [],
        [ProductCategories.DESSERTS]: [],
        [ProductCategories.DRINKS]: [],
        [ProductCategories.BASES]: [],
        [ProductCategories.STANDARDS]: [],
        [ProductCategories.PREMIUMS]: [],
        [ProductCategories.TOPPINGS]: [],
        [ProductCategories.DRESSINGS]: [],
        [ProductCategories.EXTRAS]: [],
        [ProductCategories.SIDES]: [],
      } as {
        [key in Exclude<ProductCategories, ProductCategories.ADDONS>]: string[];
      }
    );

    return categoryMap;
  } catch (err) {
    console.warn("Error while mapping fetched Products to Catalog");
  }
};
