import { atom, selector } from "recoil";

import { LastOrderedBowl } from "@/types/order";

import { InternalCartItem, PersistedCartItem } from "../../util/order";
import { formatNumber } from "../../util/product.util";
import {
  B2BCartStorageAdapter,
  CartStorageAdapter,
  DeliveryTypeStorageAdapter,
  LastOrderCustomsStorageAdapter,
  OnlineDeliveryType,
  OrderStorageAdapter,
  PreOrderIdStorageAdapter,
  SelectedPreOrderDaySlotStorageAdapter,
  SelectedPreOrderTimeSlotStorageAdapter,
  SelectedQuickSelectStorageAdapter,
  StoredPaymentMethodsStorageAdapter,
  VytalUserIdStorageAdapter,
} from "../../util/storage/order.storage";
import { parseISO8601Duration } from "../../util/time";
import { StoredPaymentMethod } from "../../views/CheckoutPageView/CheckoutPage/components/Payment/Payment.interfaces";
import {
  DaySlot,
  SlotQuickSelect,
  TimeSlot,
} from "../../views/CheckoutPageView/hooks/useDelivery/useDelivery.interfaces";
import { localStorageEffect, logCartChangeToMouseflow } from "../effect";
import Order = Definitions.Order;
import Tip = Definitions.Tip;
import Price = Definitions.Price;

export const OrderState = atom<
  Partial<Omit<Order, "cart"> & { cart: InternalCartItem[] }>
>({
  key: "_OrderState",
  default: {},
  effects: [
    localStorageEffect({
      get: OrderStorageAdapter.get,
      set: OrderStorageAdapter.set,
      delete: OrderStorageAdapter.delete,
    }),
  ],
});
export const TotalProductPriceState = atom<number>({
  key: "_TotalProductPriceState",
  default: 0,
});
export const CartState = atom<InternalCartItem[]>({
  key: "_CartState",
  default: [],
  effects: [logCartChangeToMouseflow],
});

export const PersistedCartState = atom<PersistedCartItem[]>({
  key: "_PersistedCartState",
  default: [],
  effects: [
    localStorageEffect({
      get: CartStorageAdapter.get,
      set: CartStorageAdapter.set,
      delete: CartStorageAdapter.delete,
    }),
  ],
});
export const PersistedB2BCartState = atom<PersistedCartItem[]>({
  key: "_PersistedB2BCartState",
  default: [],
  effects: [localStorageEffect(B2BCartStorageAdapter)],
});

export const CartCountState = selector<number>({
  key: "_CartCountState",
  get: ({ get }) => {
    const cart = get(CartState);
    const n = cart
      ?.filter((item) => item.id !== "tip")
      .reduce<number>((prev, current) => {
        return prev + current.count;
      }, 0);
    return n;
  },
});

export const VoucherDiscountState = selector<string | null>({
  key: "_VoucherDiscountState",
  get: ({ get }) => {
    const order = get(OrderState);
    const discount = order?.discount?.toString();
    return ["0", "", "null"].includes(discount) ? null : discount;
  },
});
export const B2BVoucherDiscountState = selector<string>({
  key: "_B2BDiscountState",
  get: ({ get }) => {
    const order = get(OrderState);
    const b2bDiscount = order?.b2bBonusCreditsUsed?.toString();
    return ["0", "", "null"].includes(b2bDiscount) ? null : b2bDiscount;
  },
});
export const VoucherValidState = atom<boolean>({
  key: "_VoucherValidState",
  default: false,
});

export const VoucherSuccessState = atom<boolean>({
  key: "_VoucherSuccessState",
  default: false,
});
export const VoucherFailedState = atom<boolean>({
  key: "_VoucherFailedState",
  default: false,
});
export const VoucherLoadingState = atom<boolean>({
  key: "_VoucherLoadingState",
  default: false,
});

export const AppliedVoucherState = atom<string>({
  key: "_AppliedVoucherState",
  default: "",
});
export const VoucherCodeState = selector<string>({
  key: "_VoucherCodeState",
  get: ({ get }) => {
    const order = get(OrderState);
    return order?.voucherCode;
  },
  set: ({ get, set }, voucherCode: string) => {
    const order = get(OrderState);
    set(OrderState, { ...order, voucherCode });
  },
});

export const TipState = selector<Tip | null>({
  key: "_TipState",
  get: ({ get }) => {
    const order = get(OrderState);
    return !!order?.tip ? order.tip : null;
  },
  set: ({ get, set }, tip: Tip | null) => {
    const order = get(OrderState);
    set(OrderState, { ...order, tip });
  },
});

export const TipAmountState = selector<string | null>({
  key: "_TipAmountState",
  get: ({ get }) => {
    const order = get(OrderState);
    const tip = order?.cart?.find((item) => item?.id === "tip");
    if (tip) {
      return tip.count.toFixed(2);
    }
    return "0.00";
  },
});

const _DeliveryPriceState = atom<Price>({
  key: "_DeliveryPriceAtom",
  default: {
    vat: "0.00",
    vatAmount: "0.00",
    withVat: "0.00",
    withoutVat: "0.00",
  },
});

export const DeliveryPriceState = selector<Price>({
  key: "_DeliveryPriceWithVatState",
  get: ({ get }) => {
    const order = get(OrderState);
    const deliveryPrice = get(_DeliveryPriceState);
    return order?.cart?.length > 0
      ? deliveryPrice
      : {
          vat: "0.00",
          vatAmount: "0.00",
          withVat: "0.00",
          withoutVat: "0.00",
        };
  },
  set: ({ get, set }, newDeliveryPrice) => {
    set(_DeliveryPriceState, newDeliveryPrice);
  },
});

const _EndPriceWithVatState = atom<string>({
  key: "_EndPriceWithVatAtom",
  default: null,
});

export const EndPriceWithVatState = selector<string>({
  key: "_EndPricWithVatState",
  get: ({ get }) => {
    const order = get(OrderState);
    const endPriceWithVat = get(_EndPriceWithVatState);
    return order?.cart?.length > 0 ? endPriceWithVat : "0,00";
  },
  set: ({ get, set }, newEndPrice) => {
    set(_EndPriceWithVatState, newEndPrice);
  },
});

export const VytalDeliveryCodeState = atom<string | undefined>({
  key: "_VytalDeliveryCodeAtom",
  default: undefined,
});

export const DeliveryTypeState = atom<OnlineDeliveryType>({
  key: "_DeliveryTypeState",
  default: OnlineDeliveryType.ONLINE_DELIVERY,
  effects: [
    localStorageEffect({
      get: DeliveryTypeStorageAdapter.get,
      set: DeliveryTypeStorageAdapter.set,
      delete: DeliveryTypeStorageAdapter.delete,
    }),
  ],
});

export const StoredPaymentMethodsState = atom<StoredPaymentMethod[]>({
  key: "_StoredPaymentMethodsState",
  default: [],
  effects: [
    localStorageEffect({
      get: StoredPaymentMethodsStorageAdapter.get,
      set: StoredPaymentMethodsStorageAdapter.set,
      delete: StoredPaymentMethodsStorageAdapter.delete,
    }),
  ],
});

export const SelectedDeliveryTimeState = atom<TimeSlot>({
  key: "_SelectedDeliveryTimeState",
  default: undefined,
});

export const SelectedQuickSelectState = atom<SlotQuickSelect | null>({
  key: "_SelectedQuickSelectState",
  default: SlotQuickSelect.EXPRESS,
  effects: [
    localStorageEffect({
      get: SelectedQuickSelectStorageAdapter.get,
      set: SelectedQuickSelectStorageAdapter.set,
      delete: SelectedQuickSelectStorageAdapter.delete,
    }),
  ],
});

export const SelectedPreOrderDaySlotState = atom<DaySlot | null>({
  key: "_SelectedPreOrderDaySlotState",
  default: null,
  effects: [
    localStorageEffect({
      get: SelectedPreOrderDaySlotStorageAdapter.get,
      set: SelectedPreOrderDaySlotStorageAdapter.set,
      delete: SelectedPreOrderDaySlotStorageAdapter.delete,
    }),
  ],
});

export const SelectedPreOrderTimeSlotState = atom<TimeSlot | null>({
  key: "_SelectedPreOrderTimeSlotState",
  default: null,
  effects: [
    localStorageEffect({
      get: SelectedPreOrderTimeSlotStorageAdapter.get,
      set: SelectedPreOrderTimeSlotStorageAdapter.set,
      delete: SelectedPreOrderTimeSlotStorageAdapter.delete,
    }),
  ],
});

export const EstimatedDeliveryDurationState = selector<number | null>({
  key: "_EstimatedDeliveryDurationState",
  get: ({ get }) => {
    const order = get(OrderState);
    return !!order?.estimatedDeliveryDuration
      ? parseISO8601Duration(order.estimatedDeliveryDuration)
      : null;
  },
});

export const OrderPendingState = atom<boolean>({
  key: "_OrderPendingState",
  default: false,
});

export const PreOrderIdState = atom<string | null>({
  key: "_PreOrderIdState",
  default: null,
  effects: [localStorageEffect(PreOrderIdStorageAdapter)],
});

export const GoogleSessionTokenState = atom<{ Uu: string } | null>({
  key: "_GoogleSessionTokenState",
  default: null,
});
export const VytalUserIdState = atom<string | undefined>({
  key: "_VytalUserIdState",
  default: undefined,
  effects: [
    localStorageEffect({
      get: VytalUserIdStorageAdapter.get,
      set: VytalUserIdStorageAdapter.set,
      delete: VytalUserIdStorageAdapter.delete,
    }),
  ],
});

export const CartValueState = selector<string>({
  key: "_CartValueState",
  get: ({ get }) => {
    const order = get(OrderState);
    return formatNumber(order?.totalProductsPriceWithVat || "0.00");
  },
});
export const LastOrderedCustomsState = atom<LastOrderedBowl[] | null>({
  key: "_LastOrderedCustomsState",
  default: [],
  effects: [localStorageEffect(LastOrderCustomsStorageAdapter)],
});

export const VoucherInputState = atom<string>({
  key: "_VoucherInputState",
  default: "",
});
