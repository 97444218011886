import merge from "lodash.merge";
import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  ProductByCategoryMap,
  ProductByIdMap,
  ProductState,
} from "../../state/Products";
import { ProductCategories } from "../../state/Products/Products.interfaces";
import { getImageUrlByProduct } from "../../util/product.util";
import HydratedProduct = Definitions.HydratedProduct;

export interface IUseProducts {
  products: HydratedProduct[];
  setProducts: (products: HydratedProduct[]) => void;
  getProductById: GetProductByIdFunc;
  getProductsByCategory: (
    category: Exclude<ProductCategories, ProductCategories.ADDONS>
  ) => HydratedProduct[];
}

export type GetProductByIdFunc = (
  productId: string
) => HydratedProduct | undefined;

const useProducts = (): IUseProducts => {
  const [products, setProducts] = useRecoilState(ProductState);
  const productMap = useRecoilValue(ProductByIdMap);
  const categoryMap = useRecoilValue(ProductByCategoryMap);

  const getProductById: GetProductByIdFunc = useCallback(
    (productId: string) => {
      const product = productMap[productId];
      if (!!product) {
        const cloned = merge({}, product, {
          image: getImageUrlByProduct(product),
          price: {
            ...product.price,
            withVat: product.price.withVat,
          },
        });
        return cloned;
      }
    },
    [productMap, getImageUrlByProduct]
  );
  const getProductsByCategory = useCallback(
    (category: Exclude<ProductCategories, ProductCategories.ADDONS>) => {
      const productIdsOfCategory = categoryMap[category];
      const products = productIdsOfCategory
        .map(getProductById)
        .filter((p) => !!p);
      return products;
    },
    [categoryMap, getProductById]
  );

  return {
    products,
    setProducts,
    getProductById,
    getProductsByCategory,
  };
};

export default useProducts;
