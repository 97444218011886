import HydratedProduct = Definitions.HydratedProduct;
import CartItem = Definitions.CartItem;
import HydratedDietaryInfo = Definitions.HydratedDietaryInfo;
import Nutrients = Definitions.Nutrients;
import { generatCustomImageUrl } from "./image";

export const getImageUrlByProduct = (
  product: HydratedProduct,
  width?: number,
  autoCrop?: boolean
) => {
  if (!product?.image) return "";
  return `${process.env.NEXT_PUBLIC_IMG_BASE}/${product?.image}${
    !!width ? `?width=${width}` : ""
  }${autoCrop ? "&autoCrop=true" : ""}`;
};

export const getImageUrlById = (
  id: string,
  width?: number,
  autoCrop?: boolean
) => {
  return `${process.env.NEXT_PUBLIC_IMG_BASE}/${id}${
    !!width ? `?width=${width}` : ""
  }${autoCrop ? "&autoCrop=true" : ""}`;
};

export const splitAddonsIntoGroups = (
  addons: (CartItem | string)[],
  products: HydratedProduct[]
): [
  /*Base Id's*/ string[],
  /*Ingredient Id's*/ string[],
  /*Dressing Id's*/ string[]
] => {
  const addonProducts = addons.map((addon) => {
    let product: HydratedProduct;
    if (typeof addon === "string") {
      product = products.find((product) => product.id === addon);
    } else {
      product = products.find((product) => product.id === addon.id);
    }
    return product;
  });
  const baseIds = addonProducts
    .filter((addon) => {
      return addon.tags.includes("ingredient.base");
    })
    .map((base) => base.id);
  const ingredientIds = addonProducts
    .filter((addon) => {
      return addon.tags.some((tag) =>
        [
          "ingredient.premium",
          "ingredient.topping",
          "ingredient.standard",
        ].includes(tag)
      );
    })
    .map((ingredient) => ingredient.id);
  const dressingIds = addonProducts
    .filter((addon) => {
      return addon.tags.includes("ingredient.dressing");
    })
    .map((dressing) => dressing.id);
  return [baseIds, ingredientIds, dressingIds];
};
export const getCustomImage = (
  size: number,
  baseIds: string[] = [],
  ingredientIds: (string | null)[],
  dressingIds: (string | null)[],
  productType: "bowl" | "salad"
) => {
  const imageUrl = `${
    process.env.NEXT_PUBLIC_API_BASE
  }/shop/customProductImage/product.${productType}?base=${baseIds.join()}&ingredients=${ingredientIds
    .filter((_, idx) => idx < 8)
    .join(",")}&dressings=${dressingIds
    .filter((_, idx) => idx < 2)
    .join(",")}&width=${size}`;
  return imageUrl;
};
export const formatNumber = (price: string | number) => {
  const priceAsFloat = typeof price === "number" ? price : parseFloat(price);
  return priceAsFloat.toFixed(2).toString().replace(".", ",");
};

export const getImageForCustomBowl = (
  customBowl: CartItem,
  width?: number
): string => {
  const bases = customBowl.addons.filter(
    (addon) => addon && addon.tags.includes("ingredient.base")
  );
  const ingredients = customBowl.addons.filter(
    (addon) =>
      addon &&
      (addon.tags.includes("ingredient.premium") ||
        addon.tags.includes("ingredient.topping") ||
        addon.tags.includes("ingredient.standard"))
  );
  const dressings = customBowl.addons.filter(
    (addon) => addon && addon.tags.includes("ingredient.dressing")
  );

  return generatCustomImageUrl({
    size: !!width ? width : 500,
    baseIds: bases.map((base) => base.id),
    ingredientIds: ingredients.map((ingredient) => ingredient.id),
    dressingIds: dressings.map((dressing) => dressing.id),
    productType: "salad",
  });
};

const BASE_NUTRIENTS = {
  fat: "0.00",
  saturatedFattyAcids: "0.00",
  carbohydrates: "0.00",
  sugar: "0.00",
  fibers: "0.00",
  protein: "0.00",
  salt: "0.00",
  energyKcal: "0.00",
};
export const mergeNutrients = (
  nutrients1: Nutrients = {},
  nutrients2: Nutrients = {}
) => {
  let nutrients = {};
  Object.keys(BASE_NUTRIENTS).forEach((nutrientKey) => {
    nutrients[nutrientKey] = (
      (parseFloat(nutrients1[nutrientKey] || "0") * 1000 +
        parseFloat(nutrients2[nutrientKey] || "0") * 1000) /
      1000
    ).toFixed(2);
  });
  return nutrients;
};
const multiplyNutrients = (nutrients: Nutrients, multiplier: number) => {
  let multipliedNutrients = {};
  Object.keys(BASE_NUTRIENTS).forEach((nutrientKey) => {
    multipliedNutrients[nutrientKey] = (
      parseFloat(nutrients[nutrientKey] || "0") * multiplier
    ).toFixed(2);
  });
  return multipliedNutrients;
};

export const mergeDietaryInfos = (
  dietaryInfo1: HydratedDietaryInfo,
  dietaryInfo2: HydratedDietaryInfo
): HydratedDietaryInfo => {
  return {
    allergens: [
      ...dietaryInfo1.allergens,
      ...dietaryInfo2.allergens.filter(
        ({ id }) =>
          dietaryInfo1.allergens.findIndex((allergen) => allergen.id === id) ===
          -1
      ),
    ],
    tags: [
      ...dietaryInfo1.tags,
      ...dietaryInfo2.tags.filter((tag) => !dietaryInfo1.tags.includes(tag)),
    ],
    nutrients: mergeNutrients(dietaryInfo1.nutrients, dietaryInfo2.nutrients),
    portionWeight: dietaryInfo1.portionWeight + dietaryInfo2.portionWeight,
  };
};

export const getDietaryInfosForAddons =
  (getProductById: (productId: string) => HydratedProduct | null) =>
  (addons: CartItem[]): HydratedDietaryInfo => {
    return addons.reduce(
      (previousValue, currentValue) => {
        const product = getProductById(currentValue.id);
        if (!product) return previousValue;
        const allergens = [
          ...previousValue.allergens,
          ...product.dietaryInfo.allergens.filter(
            ({ id }) =>
              previousValue.allergens.findIndex(
                (allergen) => allergen.id === id
              ) === -1
          ),
        ];

        const tags = [
          ...previousValue.tags,
          product.dietaryInfo.tags.filter(
            (tag) => !previousValue.tags.includes(tag)
          ),
        ];

        const nutrients = mergeNutrients(
          previousValue.nutrients,
          multiplyNutrients(product.dietaryInfo.nutrients, currentValue.count)
        );

        const portionWeight =
          product.dietaryInfo.portionWeight * currentValue.count +
          previousValue.portionWeight;

        return {
          allergens,
          tags,
          nutrients,
          portionWeight,
        };
      },
      {
        allergens: [],
        tags: [],
        nutrients: {
          fat: "0.00",
          saturatedFattyAcids: "0.00",
          carbohydrates: "0.00",
          sugar: "0.00",
          fibers: "0.00",
          protein: "0.00",
          salt: "0.00",
          energyKcal: "0.00",
        },
        portionWeight: 0.0,
      } as HydratedDietaryInfo
    ) as HydratedDietaryInfo;
  };

export const reduceIngredients = (ingredients: CartItem[]) => {
  return ingredients
    .reduce(
      (previousValue, currentValue) => {
        let addonsSegments = [...previousValue];
        if (currentValue.tags.includes("ingredient.base")) {
          addonsSegments[0].push(currentValue);
        } else if (currentValue.tags.includes("ingredient.dressing")) {
          addonsSegments[2].push(currentValue);
        } else {
          addonsSegments[1].push(currentValue);
        }
        return addonsSegments;
      },
      [[], [], []] as [CartItem[], CartItem[], CartItem[]]
    )
    .flat(1)
    .map((addon) => `${addon.count > 1 ? `${addon.count}x` : ""} ${addon.name}`)
    .join(" · ");
};
const RemovedIngredientsPrefix = "ohne ";
const RemovedIngredientsSeparator = ", ";
export const mapRemovedIngredientIdsToComments =
  (products: HydratedProduct[]) => (names: string[]) => {
    const mappedNamesWithout = names
      .filter((name) => !!name)
      .map((name) => `${RemovedIngredientsPrefix}${name}`);
    return mappedNamesWithout.join(RemovedIngredientsSeparator);
  };

export const mapCommentToRemovedIngredientIds = (comment: string) => {
  const splittedNames = comment
    .split(RemovedIngredientsSeparator)
    .map((name) => name.replace(RemovedIngredientsPrefix, ""));
  return splittedNames.filter((product) => !!product);
};
