import useSWR, { SWRConfiguration } from "swr";

import { APIResponse, PublicAPIFetcher } from "../fetcher";
import { FetcherFunctionResponse } from "../fetcher/fetcher.interfaces";
import OrderStatus = Definitions.OrderStatus;
import DeliveryGroupInfo = Definitions.DeliveryGroupInfo;
import Upsells = Definitions.Upsells;
import StoreInfo = Definitions.StoreInfo;
import VoucherAllocationOrderInfo = Definitions.VoucherAllocationOrderInfo;
import HydratedProduct = Definitions.HydratedProduct; // SWR Config:

// SWR Config:
// https://swr.vercel.app/docs/options
const debounceAPICall = async (requestPromise: Promise<any>) => {
  const results = await Promise.all([
    requestPromise,
    new Promise((res) => setTimeout(() => res(true), 350)),
  ]);
  return results[0];
};
export const getOrderStateByToken = (
  customerOrderToken: string,
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<OrderStatus> => {
  const { error, data, mutate } = useSWR<OrderStatus, string>(
    customerOrderToken,
    (token) =>
      debounceAPICall(
        PublicAPIFetcher<OrderStatus>(`/shop/orderStatus/${token}`)
      ),
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};

export const getUpsellProducts = (storeId: string): Promise<Upsells> => {
  return PublicAPIFetcher(`/shop/${storeId}/upsells`);
};

export const getAllDeliveryGroups = (): Promise<DeliveryGroupInfo[]> => {
  return PublicAPIFetcher("/shop/deliverygroup/_all");
};

export const getDeliveryGroupById = (
  deliveryGroupId: string,
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<DeliveryGroupInfo> => {
  const { error, data, mutate } = useSWR<DeliveryGroupInfo, string>(
    deliveryGroupId,
    (deliveryGroupId) =>
      PublicAPIFetcher<DeliveryGroupInfo>(
        `/shop/deliverygroup/${deliveryGroupId}?includeStoreInfos=true&longitude=9.970238&latitude=53.552659`
      ),
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};

export const getStoreById = (
  storeId: string,
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<StoreInfo> => {
  const { error, data, mutate } = useSWR<StoreInfo, string>(
    storeId,
    (storeId) => PublicAPIFetcher<StoreInfo>(`/shop/${storeId}`),
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};

export const getStoreByIdOnServer = (storeId: string) =>
  PublicAPIFetcher<StoreInfo>(`/shop/${storeId}`);

export const getPaymentMethodsForStore = (
  storeId: string,
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<StoreInfo> => {
  const { error, data, mutate } = useSWR<StoreInfo, string>(
    storeId,
    (storeId) => PublicAPIFetcher<StoreInfo>(`/shop/${storeId}`),
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};

export const validateVoucher = (props: {
  voucherCode: string;
  storeId: string;
}): Promise<VoucherAllocationOrderInfo> => {
  return PublicAPIFetcher(
    `/shop/${props.storeId}/voucher/${props.voucherCode}/availableExtended`
  );
};

export const fetchProductById = (props: {
  productId: string;
}): Promise<APIResponse<HydratedProduct>> => {
  return PublicAPIFetcher(
    `/shop/products/${encodeURIComponent(props.productId)}`,
    {
      validateStatus: (status) => status < 500,
    }
  );
};

interface ValidateVoucherForCustomerProps {
  voucherCode: string;
  storeId: string;
  email: string;
}

export const validateVoucherForCustomer = (
  props: ValidateVoucherForCustomerProps,
  swrConfig?: SWRConfiguration
) => {
  const { error, data, mutate } = useSWR<
    VoucherAllocationOrderInfo,
    ValidateVoucherForCustomerProps
  >(
    () =>
      props.storeId && props.voucherCode
        ? `validate_voucher_${props.voucherCode}_${props.storeId}${
            props.email ? `_${props.email}` : ""
          }`
        : null,
    () =>
      PublicAPIFetcher(
        `/shop/${props.storeId}/voucher/${props.voucherCode}/availableExtended${
          !!props.email
            ? `?firstTimeCustomerCheck=${encodeURIComponent(props.email)}`
            : ""
        }`
      ),
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};
