import HydratedProduct = Definitions.HydratedProduct;

export enum ProductCategories {
  BOWLS = "BOWL",
  SALADS = "SALAD",
  DESSERTS = "DESSERT",
  EXTRAS = "EXTRA",
  DRINKS = "DRINK",
  STANDARDS = "STANDARD",
  PREMIUMS = "PREMIUM",
  TOPPINGS = "TOP",
  DRESSINGS = "DRESSING",
  BASES = "BASE",
  BASES_SALADS = "BASE_SALAD",
  BASES_BOWLS = "BASE_BOWL",
  SIDES = "SIDE",
  ADDONS = "ADDON",
}

export type CategoryMap = {
  [key in Exclude<ProductCategories, ProductCategories.ADDONS>]: string[];
};
export type ProductMap = { [index: string]: HydratedProduct };
