import { atom, selector, selectorFamily } from "recoil";

import { getImageUrlByProduct } from "../../util/product.util";
import { StoreState } from "../Store/Store.state";
import {
  CategoryMap,
  ProductCategories,
  ProductMap,
} from "./Products.interfaces";
import { mapProductsToCategories } from "./util";
import HydratedProduct = Definitions.HydratedProduct;
import ProductGroup = Definitions.ProductGroup;

export const ProductGroupState = atom<ProductGroup[]>({
  key: "_ProductGroupState",
  default: [],
});

export const ProductState = atom<HydratedProduct[]>({
  key: "_ProductsState",
  default: [],
});

export const IngredientState = atom<HydratedProduct[]>({
  key: "_IngredientState",
  default: [],
});

export const ProductByIdMap = selector<ProductMap>({
  key: "_ProductByIdMap",
  get: ({ get }) => {
    const products = get(ProductState);
    let productMap: ProductMap = {};
    products.forEach((p) => (productMap[p.id] = p));
    return productMap;
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
export const ProductByCategoryMap = selector<CategoryMap>({
  key: "_ProductByCategoryMap",
  get: ({ get }) => {
    const products = get(ProductState);
    let categoryMap: CategoryMap = mapProductsToCategories(products);
    return categoryMap;
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
export const getProductByCategory = selectorFamily<
  HydratedProduct[],
  Exclude<ProductCategories, ProductCategories.ADDONS>
>({
  key: "_GetProductByCategory",
  get:
    (category) =>
    ({ get }) => {
      const CategoryMap = get(ProductByCategoryMap);
      const ProductMap = get(ProductByIdMap);
      return CategoryMap[category]
        .map((productId) => ProductMap[productId])
        .filter((p) => !!p)
        .map((product) =>
          Object.assign({}, product, { image: getImageUrlByProduct(product) })
        );
    },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: "most-recent",
  },
});

export const GetProductGroupByIdSelector = selectorFamily<
  ProductGroup | null,
  string
>({
  key: "_GetProductGroupByIdSelector",
  get:
    (productGroupId) =>
    ({ get }) => {
      const productGroups = get(ProductGroupState);
      return productGroups.find((pg) => pg.id === productGroupId) ?? null;
    },
});

export const GetProductByIdSelector = selectorFamily<
  HydratedProduct | null,
  string
>({
  key: "_GetProductByIdSelector",
  get:
    (productId) =>
    ({ get }) => {
      const store = get(StoreState);
      const product = store?.products.find(
        (product) => product.id === productId
      );
      return product ?? null;
    },
});
