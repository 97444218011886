// Check if the given date is today
export const isToday = (date: Date): boolean => {
  const now = new Date();
  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};

export const isTomorrow = (date: Date): boolean => {
  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  );
};

// Check if the given time is in the past or within a certain time frame
export const isPast = (date: Date, timeFrameMS: number): boolean => {
  const now = new Date();
  return (
    date.getTime() < now.getTime() ||
    date.getTime() < now.getTime() - timeFrameMS
  );
};

export const millisecondsToISO8601Duration = (ms: number): string => {
  let seconds = Math.floor(ms / 1000);
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  let days = Math.floor(hours / 24);
  hours = hours % 24;
  let weeks = Math.floor(days / 7);
  days = days % 7;

  let ISO8601Duration = "P";

  if (weeks) {
    ISO8601Duration += weeks + "W";
  }
  if (days) {
    ISO8601Duration += days + "D";
  }
  if (hours || minutes || seconds) {
    ISO8601Duration += "T";
  }
  if (hours) {
    ISO8601Duration += hours + "H";
  }
  if (minutes) {
    ISO8601Duration += minutes + "M";
  }
  if (seconds) {
    ISO8601Duration += seconds + "S";
  }

  return ISO8601Duration;
};

// Format duration strings in this format(ISO 8601) PT28M to milliseconds
export const parseISO8601Duration = (duration: string): number => {
  const durationRegex =
    /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;
  const matches = duration.match(durationRegex);

  if (!matches) {
    throw new Error("Invalid ISO 8601 duration string");
  }

  const [, years, months, weeks, days, hours, minutes, seconds] = matches;

  let totalMilliseconds = 0;

  if (years) {
    totalMilliseconds += parseInt(years, 10) * 365 * 24 * 60 * 60 * 1000;
  }

  if (months) {
    totalMilliseconds += parseInt(months, 10) * 30 * 24 * 60 * 60 * 1000;
  }

  if (weeks) {
    totalMilliseconds += parseInt(weeks, 10) * 7 * 24 * 60 * 60 * 1000;
  }

  if (days) {
    totalMilliseconds += parseInt(days, 10) * 24 * 60 * 60 * 1000;
  }

  if (hours) {
    totalMilliseconds += parseInt(hours, 10) * 60 * 60 * 1000;
  }

  if (minutes) {
    totalMilliseconds += parseInt(minutes, 10) * 60 * 1000;
  }

  if (seconds) {
    totalMilliseconds += parseFloat(seconds) * 1000;
  }

  return totalMilliseconds;
};
export type DayLabelFormat = "long" | "short";

export interface WeekDaysMap {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  today: string;
  tomorrow: string;
}

export const getDateDDMM = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${day}.${month}.`;
};
export const formatDateDayDDMM = (
  date: Date,
  format: DayLabelFormat
): string => {
  const weekDay = date.toLocaleDateString("de-DE", {
    weekday: format,
  });
  return weekDay;
};

export const getDaysLabel = (date: Date, format: DayLabelFormat): string => {
  if (isToday(date)) return "Heute";
  if (isTomorrow(date)) return "Morgen";
  return `${formatDateDayDDMM(date, format)}`;
};

export const getDaysLabelWithDDMM = (
  date: Date,
  format: DayLabelFormat,
  parenthesis: boolean = true
): string => {
  if (isToday(date)) return "Heute";
  if (isTomorrow(date)) return "Morgen";
  return `${formatDateDayDDMM(date, format)} ${
    parenthesis ? "(" : ""
  }${getDateDDMM(date)}${parenthesis ? ")" : ""}`;
};
export const getTimeLabel = (
  slotDate: Date,
  timeRange: number = 30,
  isDelivery: boolean = false
): string => {
  const _timeRange = Math.ceil(timeRange / 2);
  const startTime = new Date(slotDate.getTime() - _timeRange * 60 * 1000);
  const endTime = new Date(slotDate.getTime() + _timeRange * 60 * 1000);

  const formatTime = (time: Date): string => {
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes !== "0" ? minutes : "00"}`;
  };
  if (!isDelivery) return formatTime(slotDate);

  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);

  return `${formattedStartTime} - ${formattedEndTime}`;
};

export const divideTimeIntoSlots = (
  start: Date,
  end: Date,
  slotSizeInMinutes: number
): Date[] => {
  const slotDuration = slotSizeInMinutes * 60 * 1000;
  const slots: Date[] = [];

  let currentSlot = new Date(start.getTime());

  do {
    slots.push(new Date(currentSlot.getTime()));
    currentSlot.setTime(currentSlot.getTime() + slotDuration);
  } while (currentSlot.getTime() <= end.getTime());

  return slots;
};
export const isSlotAvailableForPreOrder = (
  slot: Date,
  deliveryDuration: number = 30,
  opening: Date,
  closing: Date
) => {
  const deliveryDurationInMS = deliveryDuration * 1000 * 60;
  const currentTime = new Date().getTime();
  const isInFuture = slot.getTime() > currentTime + deliveryDurationInMS;
  const isWithinOpeningHours =
    slot.getTime() >= opening.getTime() + deliveryDurationInMS &&
    slot.getTime() <= closing.getTime();

  return isInFuture && isWithinOpeningHours;
};
export const getClosestSlot = (
  slots: Date[],
  storeOpening: Date,
  storeClosing: Date,
  offsetInMinutes: number = 30
): Date => {
  const availableSlots = slots.filter((slot) =>
    isSlotAvailableForPreOrder(
      slot,
      offsetInMinutes,
      storeOpening,
      storeClosing
    )
  );
  const sortedSlots = availableSlots.sort((a, b) => a.getTime() - b.getTime());
  return sortedSlots[0];
};

export const getDDMMDateString = (dateString: string): string | null => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return null; // Invalid date string
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  return `${day}.${month}`;
};

export const getHHMMTimeString = (timeString: string): string | null => {
  const time = new Date(timeString);

  if (isNaN(time.getTime())) {
    return null; // Invalid time string
  }

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const dateIsInStoreOpeningHours = (
  date: Date,
  storeOpening: Date,
  storeClosing: Date
) => {
  return (
    date.getTime() >= storeOpening.getTime() &&
    date.getTime() < storeClosing.getTime()
  );
};
