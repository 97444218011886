export const generatCustomImageUrl = (props: {
  size: number;
  baseIds: string[];
  ingredientIds: (string | null)[];
  dressingIds: (string | null)[];
  productType: "bowl" | "salad";
}) => {
  const { size, baseIds = [], ingredientIds, dressingIds, productType } = props;
  const imageUrl = `${
    process.env.NEXT_PUBLIC_API_BASE
  }/shop/customProductImage/product.${productType}?base=${baseIds.join()}&ingredients=${ingredientIds
    .filter((_, idx) => idx < 8)
    .join(",")}&dressings=${dressingIds
    .filter((_, idx) => idx < 2)
    .join(",")}&width=${size}`;

  return imageUrl;
};
