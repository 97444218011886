import { LastOrderedBowl } from "@/types/order";

import { StoredPaymentMethod } from "../../views/CheckoutPageView/CheckoutPage/components/Payment/Payment.interfaces";
import {
  DaySlot,
  SlotQuickSelect,
  TimeSlot,
} from "../../views/CheckoutPageView/hooks/useDelivery/useDelivery.interfaces";
import StorageAdapterGenerator, {
  SessionStorageAdapterGenerator,
  StorageAdapter,
} from "./StorageAdapterGenerator";
import {
  BonusCardStorageAdapter,
  NewsletterVetoStorageAdapter,
  SelectedPaymentMethodStorageAdapter,
} from "./user.storage";
import Order = Definitions.Order;
import CartItem = Definitions.CartItem;
import Tip = Definitions.Tip;
import PaymentInfo = Definitions.PaymentInfo;

const CART_KEY = "ss.cart";
export const CartStorageAdapter = StorageAdapterGenerator<CartItem[]>(
  CART_KEY,
  [],
  true
);

const B2BCART_KEY = "ss.b2b#cart";
export const B2BCartStorageAdapter = StorageAdapterGenerator<CartItem[]>(
  B2BCART_KEY,
  [],
  true
);

const VOUCHER_CODE_KEY = "ss.voucherCode";
export const VoucherCodeStorageAdapter = StorageAdapterGenerator<string>(
  VOUCHER_CODE_KEY,
  null,
  false
);

const TIP_KEY = "ss.tip";
export const TipStorageAdapter = StorageAdapterGenerator<Tip>(
  TIP_KEY,
  null,
  true
);

const PLANNED_DELIVERY_TIME_KEY = "ss.plannedDeliveryTime";
export const DeliveryTimeStorageAdapter = StorageAdapterGenerator<string>(
  PLANNED_DELIVERY_TIME_KEY,
  "now",
  false
);
const DELIVERY_TYPE_KEY = "ss.deliveryType";

export enum OnlineDeliveryType {
  ONLINE_DELIVERY = "ONLINE_DELIVERY",
  ONLINE_TAKEOUT = "ONLINE_TAKEOUT",
  ONLINE_EATIN = "ONLINE_EATIN",
}

export const DeliveryTypeStorageAdapter =
  StorageAdapterGenerator<OnlineDeliveryType>(
    DELIVERY_TYPE_KEY,
    OnlineDeliveryType.ONLINE_DELIVERY,
    false
  );

const ORDER_KEY = "ss.partialOrder";
const PartialOrderStorageAdapter = StorageAdapterGenerator<Partial<Order>>(
  ORDER_KEY,
  {}
);
type DeliveryType =
  | "ONLINE_DELIVERY"
  | "ONLINE_TAKEOUT"
  | "ONLINE_EATIN"
  | "OFFLINE_EATIN"
  | "OFFLINE_TAKEOUT";
export const OrderStorageAdapter: StorageAdapter<Partial<Order>> =
  typeof window !== "undefined"
    ? {
        get: () => {
          const voucherCode = VoucherCodeStorageAdapter.get();
          const tip = TipStorageAdapter.get();
          const deliveryType = DeliveryTypeStorageAdapter.get();
          const vetoNewsletter = NewsletterVetoStorageAdapter.get();
          const subscribeToBonusCard = BonusCardStorageAdapter.get();
          const paymentMethod = SelectedPaymentMethodStorageAdapter.get();
          return {
            deliveryType: deliveryType as DeliveryType,
            voucherCode,
            tip: (tip as Tip) || null,
            vetoNewsletter,
            subscribeToBonusCard,
            paymentInfo: {
              method: paymentMethod,
              useCheckout: true,
            } as PaymentInfo,
          };
        },
        set: (order) => {
          const {
            cart = [],
            voucherCode = "",
            tip,
            deliveryType,
            subscribedToNewsletter,
            subscribedToBonusCard,
          } = order;
          //CartStorageAdapter.set(cart);
          VoucherCodeStorageAdapter.set(voucherCode);
          if (!!subscribedToNewsletter) {
            NewsletterVetoStorageAdapter.set(subscribedToNewsletter);
          }
          if (!!tip) {
            TipStorageAdapter.set(tip);
          } else {
            TipStorageAdapter.delete();
          }
        },
        delete: () => {
          localStorage.removeItem(ORDER_KEY);
          VoucherCodeStorageAdapter.delete();
          TipStorageAdapter.delete();
        },
      }
    : {
        get: () => ({}),
        set: () => {},
        delete: () => {},
      };

const STORED_PAYMENT_METHODS_KEY = "ss.storedPaymentMethods";

export const StoredPaymentMethodsStorageAdapter = StorageAdapterGenerator<
  StoredPaymentMethod[]
>(STORED_PAYMENT_METHODS_KEY, [], true);

const SELECTED_QUICK_SELECT_KEY = "ss.selectedQuickSelect";
export const SelectedQuickSelectStorageAdapter =
  StorageAdapterGenerator<SlotQuickSelect | null>(
    SELECTED_QUICK_SELECT_KEY,
    null,
    false
  );

const SELECTED_PREORDER_DAY_SLOT_KEY = "ss.selectedPreOrderDaySlot";
export const SelectedPreOrderDaySlotStorageAdapter: StorageAdapter<DaySlot> =
  typeof window !== "undefined"
    ? {
        get: () => {
          const storedDaySlotString = localStorage.getItem(
            SELECTED_PREORDER_DAY_SLOT_KEY
          );
          if (storedDaySlotString) {
            const storedDaySlot = JSON.parse(storedDaySlotString) as DaySlot;
            const daySlot = new Date(storedDaySlot?.timestamp).setHours(
              23,
              59,
              59,
              999
            );
            if (daySlot > Date.now()) {
              return storedDaySlot;
            }
            localStorage.removeItem(SELECTED_PREORDER_DAY_SLOT_KEY);
          }
          return null;
        },
        set: (timeSlot) => {
          localStorage.setItem(
            SELECTED_PREORDER_DAY_SLOT_KEY,
            JSON.stringify(timeSlot)
          );
        },
        delete: () => {
          localStorage.removeItem(SELECTED_PREORDER_DAY_SLOT_KEY);
        },
      }
    : {
        get: () => null,
        set: () => {},
        delete: () => {},
      };

const VYTAL_USER_ID_KEY = "ss.vytalUserId";
export const VytalUserIdStorageAdapter = StorageAdapterGenerator<
  string | undefined
>(VYTAL_USER_ID_KEY, undefined, false);
const SELECTED_PREORDER_TIME_SLOT_KEY = "ss.selectedPreOrderTimeSlot";
export const SelectedPreOrderTimeSlotStorageAdapter: StorageAdapter<TimeSlot> =
  typeof window !== "undefined"
    ? {
        get: () => {
          const storedTimeSlotString = localStorage.getItem(
            SELECTED_PREORDER_TIME_SLOT_KEY
          );
          if (storedTimeSlotString) {
            const storedTimeSlot = JSON.parse(storedTimeSlotString) as TimeSlot;
            const timeSlotDate = new Date(storedTimeSlot?.timestamp);
            if (storedTimeSlot && timeSlotDate.getTime() > Date.now()) {
              return storedTimeSlot;
            }
            localStorage.removeItem(SELECTED_PREORDER_TIME_SLOT_KEY);
          }
          return null;
        },
        set: (timeSlot) => {
          localStorage.setItem(
            SELECTED_PREORDER_TIME_SLOT_KEY,
            JSON.stringify(timeSlot)
          );
        },
        delete: () => {
          localStorage.removeItem(SELECTED_PREORDER_TIME_SLOT_KEY);
        },
      }
    : {
        get: () => null,
        set: () => {},
        delete: () => {},
      };

const LAST_ORDERED_CUSTOMS_KEY = "ss.lastOrderedCustoms";
export const LastOrderCustomsStorageAdapter = StorageAdapterGenerator<
  LastOrderedBowl[]
>(LAST_ORDERED_CUSTOMS_KEY, [], true);

const PRE_ORDER_ID_KEY = "ss.preOrderId";
export const PreOrderIdStorageAdapter = SessionStorageAdapterGenerator<string>(
  PRE_ORDER_ID_KEY,
  null,
  false
);
