export interface UseDeliveryProps {
  slotSizeInMinutes?: number;
  slotRangeInMinutes?: number;
}

export interface IUseDelivery {
  setSlotQuickSelect: (selected: SlotQuickSelect) => void;
  slotQuickSelect: SlotQuickSelect;
  showSlots: boolean;
  daySlots: DaySlot[];
  timeSlots: TimeSlot[][];
  expressSlot: TimeSlot;
  selectedTimeSlot: TimeSlot;
  selectedPreOrderDaySlot: DaySlot;
  setSelectedPreOrderDaySlot: (slot: DaySlot) => void;
  selectedPreOrderTimeSlot: TimeSlot;
  setSeletedPreOrderTimeSlot: (slot: TimeSlot) => void;
  daySlotsExtended: boolean;
  timeSlotsExtended: boolean;
  setDaySlotsExtended: (extended: boolean) => void;
  setTimeSlotsExtended: (extended: boolean) => void;
  setDeliverySlotsVisible: (visible: boolean) => void;
  getTimeSlots: (businessDayId?: string) => TimeSlot[][];
}

export interface TimeSlot {
  available: boolean;
  timestamp: number;
  isClosest?: boolean;
  isASAP?: boolean;
}

export interface DaySlot extends Omit<TimeSlot, "isNext"> {
  id: string;
  dateLabel: string;
}

export enum SlotQuickSelect {
  EXPRESS = "express",
  PRE_ORDER = "pre-order",
}
