import { atom, selector } from "recoil";

import {
  FirstTimeUserScreenVisitedStorageAdapter,
  UserLocationMatchedStorageAdapter,
} from "../../util/storage/menu.storage";
import {
  HeroProps,
  VoucherProps,
} from "../../views/MenuViewPage/components/StoreInfoSelect/StoreInfoSelect.interfaces";
import { localStorageEffect } from "../effect";

export const UnavailableProductModalState = atom<boolean>({
  key: "UnavailableProductModalState",
  default: false,
});

export const FirstTimeUserScreenVisitedState = atom<boolean>({
  key: "FirstTimeUserScreenVisitedState",
  default: false,
  effects: [localStorageEffect(FirstTimeUserScreenVisitedStorageAdapter)],
});

export const FirstTimeUserState = atom<boolean>({
  key: "FirstTimeUserState",
  default: false,
});

export const UserLocationMatchedState = atom<string | null>({
  key: "UserLocationMatchedState",
  default: null,
  effects: [localStorageEffect(UserLocationMatchedStorageAdapter)],
});

export const NewAddedProductSequenceIDsState = atom<number[]>({
  key: "NewAddedProductSequenceIDsState",
  default: [],
});

export const HeroVisibleState = atom<boolean>({
  key: "HeroVisibleState",
  default: true,
});

export const HeroBannerPropsState = atom<HeroProps | null>({
  key: "_HeroBannerProps",
  default: null,
});
export const HeroVoucherPropsSelector = selector<VoucherProps | null>({
  key: "_HeroVoucherProps",
  get: ({ get }) => {
    const heroBannerProps = get(HeroBannerPropsState);
    return heroBannerProps?.voucher ?? null;
  },
});

export const HeroBannerPropsInitiatedState = atom<boolean>({
  key: "_HeroBannerPropsInitiatedState",
  default: false,
});
