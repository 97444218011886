import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const _LoadingElement = styled.div<{
  baseColor?: string;
  highlightColor?: string;
}>`
  ${(props) => css`
    @keyframes react-loading-skeleton {
      100% {
        transform: translateX(100%);
      }
    }
    --base-color: ${props.baseColor ? props.baseColor : "#ebebeb"};
    --highlight-color: ${props.highlightColor
      ? props.highlightColor
      : "#f5f5f5"};
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    background-color: var(--base-color);

    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;

    position: relative;
    overflow: hidden;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

    &::after {
      content: " ";
      display: var(--pseudo-element-display);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-image: linear-gradient(
        90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color)
      );
      transform: translateX(-100%);

      animation-name: react-loading-skeleton;
      animation-direction: var(--animation-direction);
      animation-duration: var(--animation-duration);
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  `};
  display: block;
  height: 100%;
  width: 100%;
  z-index: 100;
`;
export const _LoadingWrapper = styled.div<{
  width: string;
  height: string;
  maxWidth?: string;
  maxHeight?: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : null)}
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight};` : null)}
  display: block;
`;
