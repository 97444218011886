import { FC } from "react";

import { _LoadingElement, _LoadingWrapper } from "./Loading.styled";

const Loading: FC<{
  width: string;
  height: string;
  maxWidth?: string;
  maxHeight?: string;
  baseColor?: string;
  highlightColor?: string;
  className?: string;
}> = ({
  width,
  height,
  maxWidth,
  maxHeight,
  highlightColor,
  baseColor,
  className,
}) => {
  return (
    <_LoadingWrapper
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      className={className}
    >
      <_LoadingElement highlightColor={highlightColor} baseColor={baseColor} />
    </_LoadingWrapper>
  );
};

export default Loading;
