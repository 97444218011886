import StoreInfo = Definitions.StoreInfo;
import DeliveryGroupInfo = Definitions.DeliveryGroupInfo;
import { atom } from "recoil";

import { ActiveStoreStorageAdapter } from "../../util/storage/store.storage";
import { StoreShiftType } from "../../views/MenuViewPage/components/StoreInfoSelect/StoreInfoSelect.interfaces";
import { localStorageEffect } from "../effect";

const ActiveStoreId = atom<string | null>({
  key: "_ActiveStoreId",
  default: null,
  effects: [
    localStorageEffect<string>({
      get: ActiveStoreStorageAdapter.get,
      set: ActiveStoreStorageAdapter.set,
      delete: ActiveStoreStorageAdapter.delete,
    }),
  ],
});
export const StoreState = atom<StoreInfo>({
  key: "_Stores",
  default: null,
});
export const ActiveDeliveryGroupState = atom<DeliveryGroupInfo | null>({
  key: "_ActiveDeliveryGroupState",
  default: null,
});

export const StoreShiftState = atom<StoreShiftType | null>({
  key: "_StoreShiftState",
  default: null,
});
